import { UserPermissionsAccessMethodEnum } from '@root/environments/user-permissions-access-method.enum';

export const environment = {
  production: true,
  isDevelopment: JSON.parse('#{isDevelopment}#'.toLowerCase()),
  loginOnBehalfEnabled: '#{loginOnBehalfEnabled}#',
  userPermissions: UserPermissionsAccessMethodEnum.EndPoint,
  default_lang: 'ar',
  versionNumber: '#{versionNumber}#',
  apiUrl: '#{v2ApiUrl}#',
};
